import React, { useEffect } from "react"
import Header from "../header/header"
import Footer from "../footer/footer"
import ScrollController from "./scrollController"
import "../../css/index.scss"

const Layout = ({ children, page }) => {
  let footerColor = "white"
  if (["news-archive", "news-single", "error-404"].includes(page)) {
    footerColor = "gray"
  }
  useEffect(() => {
    ScrollController()
  }, [])
  return (
    <div id={page} className="wrapper">
      <Header page={page} />
      <div className="contents">{children}</div>
      <Footer color={footerColor} />
    </div>
  )
}
export default Layout
