import React from "react"
import { Link } from "gatsby"
import Nav from "./nav"
import SnsIcon from "../sns/sns"

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      headerState: "close",
      aboutState: "close",
      vertical: false,
    }
    this.toggleSwitch = this.toggleSwitch.bind(this)
    this.toggleReset = this.toggleReset.bind(this)
    this.winResize = this.winResize.bind(this)
    this.setHeaderClass = this.setHeaderClass.bind(this)
  }

  componentDidMount() {
    this.firstInit()
    window.addEventListener("resize", this.winResize)
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.winResize)
  }

  firstInit = () => {
    this.winResize()
    if (["top", "about"].indexOf(this.props.page) !== -1) {
      this.setHeaderClass()
    }
  }

  winResize = () => {
    const winH = window.innerHeight
    const winW = window.innerWidth
    if (winW > 812) {
      return false
    }
    if (winW > winH) {
      this.setState({ vertical: true })
    } else {
      this.setState({ vertical: false })
    }
  }

  toggleSwitch = e => {
    e.preventDefault()
    const currentState = this.state.headerState
    const nextState = currentState === "open" ? "close" : "open"
    this.setState({ headerState: nextState })
  }
  toggleReset = e => {
    this.setState({ headerState: "close" })
  }

  setHeaderClass = e => {
    const page = this.props.page
    const target = document.getElementById("main-visual")
    const header = document.getElementById("header")
    const headerClass =
      page === "top"
        ? ["header", "header--wh", "logo-hide"]
        : ["header", "header--wh"]
    const cb = entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          header.className = ""
          headerClass.forEach(function(myclass) {
            header.classList.add(myclass)
          })
        } else {
          header.className = ""
          header.classList.add("header")
        }
      })
    }
    const options = {
      root: null,
      rootMargin: "-25px 0px 0px",
    }
    const observer = new IntersectionObserver(cb, options)
    if (target) {
      observer.observe(target)
    }
  }

  render() {
    const headerState = this.state.headerState
    const menuText = headerState === "open" ? "close" : "menu"
    const verClass = this.state.vertical ? "on" : ""

    return (
      <header
        id="header"
        data-toggle={headerState}
        data-vertical={verClass}
        className="header"
      >
        <div className="header__in">
          <div className="header__top">
            <button
              type="button"
              className="header__trigger"
              onClick={this.toggleSwitch}
            >
              <span className="header__hum">
                <span className="header__hum-01"></span>
                <span className="header__hum-02"></span>
                <span className="header__hum-03"></span>
              </span>
              {menuText}
            </button>
            <Link
              to="/"
              className="header__top-logo"
              onClick={this.toggleReset}
            >
              <span className="header__top-svg"></span>
            </Link>
            {/* <SnsIcon snsClass="header__top-sns" flex="on" /> */}
          </div>
          <div className="header__main">
            <p className="header__text-1">
              True quality is likely something similar to
              <br />
              affection and a contented smile.
            </p>
            <Nav />
            <p className="header__text-2">
              japan &amp; barcelona &amp; shanghai
            </p>
            <SnsIcon snsClass="header__sns" />
          </div>
        </div>
      </header>
    )
  }
}

export default Header
