import React from "react"
import SnsIcon from "../sns/sns"

const Footer = props => {
  let footerClass = ["footer", "en"]
  if (props.color === "gray") {
    footerClass.push("footer--gray")
  }

  return (
    <footer id="footer" className={footerClass.join(" ")}>
      <div className="footer__in">
        <p className="footer__logo">
          <img src="/image/common/footer_logo.svg" alt="" />
        </p>
        <div className="footer__main">
          <div className="footer__info">
            <ul className="footer__info-list">
              <li className="footer__info-item">
                <span className="footer__info-title">e.</span>
                <span className="footer__info-text">info@1moku.co.jp</span>
              </li>
              <li className="footer__info-item">
                <span className="footer__info-title">t.</span>
                <span className="footer__info-text">+81-(0)3-6451-0846</span>
              </li>
              <li className="footer__info-item">
                <span className="footer__info-title">f.</span>
                <span className="footer__info-text">+81-(0)3-6451-0847</span>
              </li>
              <li className="footer__info-item">
                <span className="footer__info-title">w.</span>
                <span className="footer__info-text">www.1moku.co.jp</span>
              </li>
            </ul>
          </div>
          <div className="footer__office">
            <p className="footer__location">
              <span className="footer__location-mark">tokyo, japan</span>head
              office
            </p>
            <p className="footer__address">
              2f
              <span className="footer__company">(artless Inc.)</span>,
              <br className="pc" />
              5-27-8 jingumae
              <br className="pc" />
              shibuya-ku tokyo <br className="sp" />
              150-0001 japan
            </p>
          </div>
          <div className="footer__office">
            <p className="footer__location">
              <span className="footer__location-mark">barcelona, spain</span>
              creative studio
            </p>
            <p className="footer__address">C／Manso 45, 08205, sabadell</p>
          </div>
        </div>
        <div className="footer__bottom">
          <small className="footer__copy">
            &copy; 2017 1moku landscape design &amp; research
          </small>
        </div>
        <SnsIcon snsClass="footer__sns" />
      </div>
    </footer>
  )
}

export default Footer
