import React from "react"

const SnsIcon = props => {
  const snsClass = props.snsClass ? ["sns", props.snsClass] : ["sns"]
  const list =
    props.flex === "on" ? ["sns__list", "sns__list--flex"] : ["sns__list"]
  return (
    <div className={snsClass.join(" ")}>
      <ul className={list.join(" ")}>
        <li className="sns__item">
          <a
            className="sns__link"
            target="_blank"
            href="https://www.instagram.com/1moku_japan/"
            rel="noreferrer noopener"
          >
            <span className="icon-instagram"></span>
          </a>
        </li>
        <li className="sns__item">
          <a
            className="sns__link"
            target="_blank"
            href="https://www.pinterest.jp/1moku_japan/"
            rel="noreferrer noopener"
          >
            <span className="icon-pinterest"></span>
          </a>
        </li>
      </ul>
    </div>
  )
}

export default SnsIcon
