const ScrollController = () => {
  const targets = document.querySelectorAll("*[data-scroll]")
  const cb = entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        entry.target.setAttribute("data-scroll", "show")
        observer.unobserve(entry.target)
      }
    })
  }
  const options = {
    root: null,
    rootMargin: "0px 0px -50px",
  }
  const observer = new IntersectionObserver(cb, options)
  if (targets.length) {
    const nodes = Array.prototype.slice.call(targets, 0)
    nodes.forEach(target => {
      observer.observe(target)
    })
  }
}

export default ScrollController
