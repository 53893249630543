import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const Meta = props => {
  const data = useStaticQuery(graphql`
    query metaQuery {
      site {
        siteMetadata {
          siteName
          description
          keyword
          siteUrl
          ogpUrl
        }
      }
    }
  `)
  const meta = data.site.siteMetadata

  const title = props.title || meta.siteName
  const description = props.description || meta.description
  const url = props.url || meta.siteUrl
  const ogpUrl = props.ogImgUrl || meta.ogpUrl
  return (
    <Helmet htmlAttributes={{ lang: "ja" }}>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={meta.keyword} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content={props.type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content={meta.siteName} />
      <meta property="og:image" content={ogpUrl} />
    </Helmet>
  )
}

export default Meta
