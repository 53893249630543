import React from "react"
import { Link } from "gatsby"

class Nav extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      aboutState: "close",
    }
    this.aboutToggle = this.aboutToggle.bind(this)
  }
  aboutToggle = e => {
    const currentState = this.state.aboutState
    const nextState = currentState === "open" ? "close" : "open"
    this.setState({ aboutState: nextState })
  }
  render() {
    const aboutState = this.state.aboutState

    return (
      <nav className="header__navi navi">
        <ul className="navi__list">
          <li className="navi__item">
            <Link className="navi__link" to="/projects">
              <span className="navi__num">1{}.</span>
              <span className="navi__text">projects</span>
            </Link>
          </li>
          <li className="navi__item navi__item--child" data-toggle={aboutState}>
            <button
              onClick={this.aboutToggle}
              type="button"
              className="navi__link"
            >
              <span className="navi__num">2{}.</span>
              <span className="navi__text">about us</span>
            </button>
            <div className="navi__child">
              <ul className="navi__child-list">
                <li className="navi__child-item">
                  <Link className="navi__link" to="/about">
                    about 1moku
                  </Link>
                </li>
                <li className="navi__child-item">
                  <Link className="navi__link" to="/company">
                    company guide
                  </Link>
                </li>
                <li className="navi__child-item">
                  <Link className="navi__link" to="/recruit">
                    recruitment
                  </Link>
                </li>
                <li className="navi__child-item">
                  <Link className="navi__link" to="/media">
                    media
                  </Link>
                </li>
                <li className="navi__child-item">
                  <Link className="navi__link" to="/exhibition">
                    exhibition
                  </Link>
                </li>
                <li className="navi__child-item">
                  <Link className="navi__link" to="/works">
                    works
                  </Link>
                </li>
              </ul>
            </div>
          </li>
          <li className="navi__item">
            <Link className="navi__link" to="/contact">
              <span className="navi__num">3{}.</span>
              <span className="navi__text">
                contact<span className="navi__label en">tokyo | barcelona</span>
              </span>
            </Link>
          </li>
          <li className="navi__item">
            <Link className="navi__link" to="/news">
              <span className="navi__num">4{}.</span>
              <span className="navi__text">news</span>
            </Link>
          </li>
        </ul>
      </nav>
    )
  }
}

export default Nav
